<template>
  <div
    class="info__form"
    :key="c"
  >
    <forms-input
      v-model="$user.user.firstname"
      :placeholder="$t('conn3ct-wallet.firstname_placeholder')"
      required
    >{{ $t('conn3ct-wallet.firstname_lbl') }}</forms-input>

    <forms-input
      v-model="$user.user.lastname"
      :placeholder="$t('conn3ct-wallet.lastname_placeholder')"
      required
    >{{ $t('conn3ct-wallet.lastname_lbl') }}</forms-input>

    <forms-select
      v-model="country"
      :placeholder="$t('conn3ct-wallet.select')"
      required
      :loading="$user.cLoading"
      :options="countries"
    >
      <template #result="{value}">{{ value.label }}</template>
      {{ $t('conn3ct-wallet.select_country_lbl') }}
    </forms-select>

    <actions-button
      @click="onConfirm"
      :appearance="$pepper.Appearance.PRIMARY"
      class="onboarding__action"
      :disabled="!isValid"
      :loading="loading"
    >{{ $t('conn3ct-wallet.confirm_action') }}</actions-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SaylConnectLanding',

  data() {
    return {
      country: null,
      countries: null,
      loading: false,
    }
  },

  computed: {
    ...mapState({
      bootstrap: state=> state.bootstrap.data
    }),

    c() {
      return this.countries?.length
    },

    isValid() {
      return !this.$basil.isNil(this.country) &&
        !this.$basil.isNil(this.$user.user.firstname) && !this.$basil.isEmpty(this.$user.user.firstname) &&
        !this.$basil.isNil(this.$user.user.lastname) && !this.$basil.isEmpty(this.$user.user.lastname)
    },

    title() {
      return this.$t('conn3ct-wallet.login_terms_title')
    }
  },

  methods: {
    async init() {
      try {
        this.countries = await this.$user.countries()

        this.setCountry()
      } catch(e) {
        $console.error(e)
      }
    },

    setCountry() {
      let geoCode = this.$basil.get(this.bootstrap, 'geolocation_country_code')

      if(!this.$basil.isNil(geoCode)) {
        let country = this.countries.find(c => c.isoCode2 === geoCode)
        if(!this.$basil.isNil(country)) {
          this.country = country.id
        }
      }
    },

    async onConfirm() {
      try {
        this.loading = true

        await this.$user.update(this.$user.user)
        await this.$user.setCountry(this.country)

        let user = await this.$user.getUser()

        if(user.lang) {
          this.setLocale(user.lang)
        }

        this.$emit('next')
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0)
    this.init()
  }
}
</script>
